@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f9f9f9;
  color: #000;
}
h1,
h2 {
  font-weight: 600;
}
h3 {
  font-weight: 500;
}
a {
  font-size: 17px;
}
ul,
li,
a,
button,
input,
textarea {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  list-style-type: none;
}

/* loader https://codepen.io/ig_design/pen/BajVZre */
.loader {
  position: fixed;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  opacity: 1;
  transition: opacity 250ms 700ms ease;
}
.loader-wrap {
  position: relative;
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 20px;
  -ms-flex-item-align: center;
  align-self: center;
}

@media screen and (max-width: 500px) {
	.loader-wrap {
		width: calc(100% - 40px);
		padding-bottom: 15px;
	}
	.loader-wrap p {
	  padding: 15px 20px 0 20px;
	}
}


::placeholder {
  font-size: 17px;
}
.flex {
  display: flex;
  justify-content: space-between;

}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  color: #5a5a5a;
  font-size: 15px;
}
label {
  color: #999;
  font-size: 13px;
}
form,
.boxItems {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 20px;
  background: #fff;
  box-shadow: 5px 5px 5px lightgray;
}
.scheduleForm {
   /* Background pattern from Toptal Subtle Patterns */
   background-image: url("assets/images/elements/schedule.jpg");
   background-repeat: no-repeat, no-repeat;
   background-size: cover;
   background-position: 0 0;
   width: 100%;
   min-height: 50vh;
}
form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  margin-top: 80px;
  padding: 40px;
}
form span {
  margin-bottom: 20px;
}
form textarea,
.accountInfo .right input,
form input {
  border: 1px solid #212121;
  border-radius: 6px;
  color: #212121;
  font-size: 15px;
  min-height: 44px;
  padding: 0 20px;
  transition: border-color 0.2s ease;
  width: 100%;
  margin-bottom: 30px;
}
.button {
  background: #212121;
  border: 1px solid;
  border-color: #212121;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  height: 48px;
  line-height: 1;
  padding: 15px 28px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}
.gridCell20 {
  width: 20%;
}
.gridCell80 {
  width: 80%;
}
.cardimg {
  width: 100%;
}
footer {
  padding: 10px;
}
footer .icon {
  font-size: 20px;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .grid3,
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.whiteText {
  color: white;
}
