header {
  background: #fff;
  padding: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: relative;
  z-index: 44;
}

.header_grid {
  display:grid;
  grid-template-columns: 10% 80% 10%;
  vertical-align: top;
}

/* columns */
.header_grid > * {
  padding:0.0rem;
}

.underline_red {
  text-decoration: underline overline #38485c;
}
.menuColor {
  color: #38485c;
}
.logo {
  width: fit-content;
  height: fit-content;
  vertical-align: top;
}
.logotitle {
  display: inline;
  width: fit-content;
  height: fit-content;
  vertical-align: top;
}
.logotitlesmall {
  display: none;
  width: fit-content;
  height: fit-content;
  vertical-align: top;
}

/* User */
.profile {
  margin-left: 30px;
  cursor: pointer;
}
.profile .image {
  display: flex;
  padding-bottom: 20px;
}
.profile .image img {
  margin-right: 20px;
}
.profile button {
  font-size: 17px;
  font-weight: 500;
}
.profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.openProfile {
  color: #000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: absolute;
  top: 80px;
  right: 10px;
  width: 250px;
  background: #fff;
}
.openProfile .box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  transition: 0.5s;
}
.openProfile .icon {
  font-size: 20px;
  margin-right: 20px;
}
.openProfile h4 {
  font-size: 15px;
  font-weight: 500;
}
.openProfile button:hover {
  background: rgba(147, 75, 255, 0.075);
}
header.active {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px 0 rgb(115 115 115 / 20%);
  z-index: 9999;
  background: #fff;
}
@media screen and (max-width: 768px) {
  header .search  {
    display: none;
  }
}



/* Clearing floats */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

.localeSign {
  padding-top: 10px;
}
/* Mini reset, no margins, paddings or bullets */
.menu,
.submenu {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Main level */
.menu {
  margin: 10px auto;
  padding-top: 10px;
  /* http://www.red-team-design.com/horizontal-centering-using-css-fit-content-value */
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

.menu > li {
  background: #34495e;
  float: left;
  position: relative;
  transform: skewX(25deg);
}

.menu a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-family: Arial, Helvetica;
  font-size: 14px;
}

.menu li:hover {
  background: #e74c3c;
}

.menu > li > a {
  transform: skewX(-25deg);
  padding: 1em 2em;
}

/* Dropdown */
.submenu {
  position: absolute;
  width: 200px;
  left: 50%; margin-left: -100px;
  transform: skewX(-25deg);
  transform-origin: left top;
}

.submenu li {
  background-color: #34495e;
  position: relative;
  overflow: hidden;
}

.submenu > li > a {
  padding: 1em 2em;
}

.submenu > li::after {
  content: '';
  position: absolute;
  top: -125%;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 50px rgba(0, 0, 0, .9);
}

/* Odd stuff */
.submenu > li:nth-child(odd){
  transform: skewX(-25deg) translateX(0);
}

.submenu > li:nth-child(odd) > a {
  transform: skewX(25deg);
}

.submenu > li:nth-child(odd)::after {
  right: -50%;
  transform: skewX(-25deg) rotate(3deg);
}

/* Even stuff */
.submenu > li:nth-child(even){
  transform: skewX(25deg) translateX(0);
}

.submenu > li:nth-child(even) > a {
  transform: skewX(-25deg);
}

.submenu > li:nth-child(even)::after {
  left: -50%;
  transform: skewX(25deg) rotate(3deg);
}

/* Show dropdown */
.submenu,
.submenu li {
  opacity: 0;
  visibility: hidden;
}

.submenu li {
  transition: .2s ease transform;
}

.menu > li:hover .submenu,
.menu > li:hover .submenu li {
  opacity: 1;
  visibility: visible;
}

.menu > li:hover .submenu li:nth-child(even){
  transform: skewX(25deg) translateX(15px);
}

.menu > li:hover .submenu li:nth-child(odd){
  transform: skewX(-25deg) translateX(-15px);
}

.menu-toggle {
    display: none; /* Змушуємо кнопку бути невидимою за замовчуванням */
}

/* Одна зміна для малих екранів: скриваємо зовнішній вигляд родинного меню */
@media only screen and (max-width: 1100px) {
  .logotitle {
    display: none;
  }
  .logotitlesmall {
    display: inline;
  }

  .menu {
    display: none; /* Сховати меню за замовчуванням */
  }
  .menu.active {
    display: block; /* Відображаємо меню при наявності класу "active" */
    position: absolute;
    left: 0px;
    margin: 10px auto;
    padding-top: 10px;
    /* http://www.red-team-design.com/horizontal-centering-using-css-fit-content-value */
    /*width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    width: 90%; /* Вирівнювання меню по ширині екрану */
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
  }

  .menu-toggle {
        display: block; /* Показуємо кнопку на малих екранах */
        background: #fff;
        color: #fff;
        padding-top: 10px;
        border: none;
        cursor: pointer;
  }
  .menu-toggle.active + .menu {
        display: block; /* Показуємо меню при кліку на активну кнопку */
  }

  .menu > li {
    display: block;
    width: 100%;
    transform: skewX(0);
  }

  .menu > li > a {
    transform: skewX(0);
  }

  .submenu > li:nth-child(odd) > a {
    transform: skewX(0);
  }

  .submenu > li:nth-child(even) > a {
    transform: skewX(0);
  }

  .submenu {
    position: static;
    width: 100%;
    left: 0;
    margin-left: 0;
    padding-left: 20px;
    transform: skewX(0);
    transform-origin: left top;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }

  .submenu li:nth-child(odd),
  .submenu li:nth-child(even) {
    transform: skewX(0) translateX(0);
  }

  .submenu > li:nth-child(odd)::after,
  .submenu > li:nth-child(even)::after {
    right: 0;
    left: 0;
    transform: skewX(0) translateX(0);
  }

  .menu > li:hover .submenu {
    max-height: 500px; /* Задайте висоту, яку ви хочете відобразити */
  }

  .menu > li:hover .submenu li {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
  }

  .menu > li:hover .submenu li:nth-child(even),
  .menu > li:hover .submenu li:nth-child(odd) {
    transform: skewX(0) translateX(0);
  }

}

