.backImg {
  width: 100%;
  min-height: 50vh;
  position: relative;
}
.backImg img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.backImg15 {
  width: 100%;
  min-height: 15vh;
  position: relative;
}
.backImg15 img {
  width: 100%;
  min-height: 15vh;
  object-fit: cover;
}

/* Add custom styling for specific images (e.g., icons) */
.backImg img.icon32 {
  width: 32px; /* your custom width */
  height: 32px; /* your custom height */
  object-fit: contain; /* adjust as needed */
}
.text {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.text_container_dark {
  position: absolute;
  top: 30%;
  left: 35%;
  width: 50%;
  z-index: 1;
  color: #fff;
  min-height: 500px;
}
@media screen and (max-width: 768px) {
  .text_container_dark {
    width: 80%;
    left: 10%;
  }
}

.h1_15 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.text_container_dark_15 {
  position: absolute;
  top: 18%;
  left: 35%;
  width: 50%;
  z-index: 1;
  color: #fff;
  min-height: 15hv;
}

.text_container_dark_15_top {
  position: absolute;
  top: 1%;
  left: 35%;
  width: 50%;
  z-index: 1;
  color: #fff;
  min-height: 15hv;
}

@media screen and (max-width: 1100px) {
  .text_container_dark_15 {
    width: 80%;
    left: 10%;
  }
  .text_container_dark_15_top {
    width: 80%;
    left: 10%;
  }
  .h1_15 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
}

.icon32 {
  width: 32px;
  height: 32px;
}
.icon64 {
  width: 64px;
  height: 64px;
}


.text_container_light {
  margin: 0 0 50px 0;
}
.text_container_light .container {
  padding: 50px;
  max-width: 60%;
  margin: auto;
}
.text_container_light .content {
  margin-top: 80px;
  display: flex;
  justify-content: flex-start;
}
.text_container_light .left {
  width: 150px;
  position: relative;
}
.text_container_light .right {
  width: calc(100% - 180px);
  margin-left: 50px;
}

.text_container_light .left .img input {
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 2;
  opacity: 0;
}
.text_container_light .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  z-index: 1;
  border-radius: 50%;
}

.text_container_light .right label {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
  display: block;
}

@media screen and (max-width: 2048px) {
  .text_container_light {
    margin: 0 0 50px 0;
  }
  .text_container_light .container {
    padding: 1px;
    max-width: 90%;
    margin: auto;
  }
  .text_container_light .content {
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
  }

}

/* container */
.article_grid {
  display:block;
  text-align: justify;
}

/* columns */
.article_grid > * {
  padding:2rem;
}

.article_grid p::first-letter {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}

.helpimg {
  padding-bottom: 20px;
  padding-top: 20px;
}

/* tablet breakpoint */
@media (min-width:768px) {
    .article_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.text_container_dark h3 {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}
.text_container_dark h2 {
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}
.text_container_dark h1 {
  font-size: 40px;
  text-align: center;
}

.text_container_dark_15 h1 {
  font-size: 40px;
  text-align: center;
}

.text_container_dark_15_top input::placeholder {
  color: #ccc; /* Світліший колір для тексту placeholder */
  font-size: 30px; /* Розмір букв 40px */
}

.text_container_dark_15_top input:focus {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.text_container_dark_15_top input {
  border: 2px solid #dadada;
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 30px; /* Розмір букв 40px */
  width: 100%;
  max-width: 400px;
  text-align: center; /* Текст вирівняний по центру */
  color: #fff; /* Білий колір тексту */
  background-color: #555; /* Темний фон для інпуту */
}

.text_container_light h3 {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}
.text_container_light h2 {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
  text-align: center;
}
.text_container_light h1 {
  font-size: 40px;
  text-align: center;
}

.common .text h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.common .text h1 {
  font-size: 40px;
}


.contact_grid {
  display: grid;
  width: 100%;
  grid-template-columns: 10% 90%;
  grid-gap: 20px;
}

.container a {
  position: relative;
  display: inline-block;
  color: #32657b;
  margin: 0 -.25rem;
  padding: 0 .25rem;
  transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.container a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #32657b;
  z-index: -1;
  transition: transform .3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.container a:hover::before {
  transform: scaleX(1);
}

.container a:hover {
  color: white;
}

.contact_grid_areaA {
  text-align: right;
}

.contact_grid.areaB {
  text-align: left;
}

.container_100 {
  max-width: 100%;
  min-height: fit-content;
  margin: auto;
}

.container {
  max-width: 95%;
  min-height: fit-content;
  margin: auto;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.textInBox {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.backLightGray {
  background: #eee;
}


.webArchiveYearHeader {
  padding: 10px;
}


.news_text_shadow {
  text-align: center;
  color: #000000;
  font-size: 20px;
  text-shadow: 0px 1px 0px #CCC, 0px 2px 0px #AAA, 0px 2px 2px #666;
}
