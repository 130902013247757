.login {
  position: relative;
  margin-bottom: 50px;
}
.login .backImg {
  width: 100%;
  height: 50vh;
  position: relative;
}
.login .backImg img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.login .text {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.login .text h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.login .text h1 {
  font-size: 40px;
}
